<div class="d-flex align-items-center justify-content-center flex-column gap-5 p-4">
    <eb-h2 class="d-flex align-items-center justify-content-center my-3">
        <eb-label
            color="primary"
            size="lg"
            textAlign="center"
            textTransform="uppercase"
            weight="bold"
        >
            {{ 'onboarding.title' | transloco: { username: ref?.data?.userName } }}
        </eb-label>
    </eb-h2>
    <eb-label
        class="onboarding__introduction"
        [innerHTML]="'onboarding.introduction' | transloco"
        size="lg"
        textAlign="center"
    />
    <eb-label
        [innerHTML]="'onboarding.greetings' | transloco"
        size="lg"
        textAlign="center"
    />
    <button
        eb-button
        (click)="startFreemium()"
    >
        <eb-label
            size="lg"
            weight="bold"
        >
            {{ 'common.start' | transloco }}
        </eb-label>
    </button>
</div>
<div class="onboarding__promote-premium">
    <div class="d-flex gap-4 align-items-center mx-4">
        <div class="onboarding__try-premium-icon">
            <eb-icon
                class="w-100"
                icon="ui/common/go-premium"
            />
        </div>

        <div class="d-flex gap-3 flex-wrap-wrap">
            @if (isResumeFeatureOperation) {
                <eb-label
                    [innerHTML]="
                        'onboarding.premium_promotion.resume_feature'
                            | transloco
                                : {
                                      featureName: ref?.data?.resumeFeatureOperation?.data?.name | transloco,
                                  }
                    "
                    size="lg"
                />
            } @else {
                <eb-label
                    size="lg"
                    textTransform="uppercase"
                    weight="bold"
                >
                    {{ 'onboarding.premium_promotion.title' | transloco }}
                </eb-label>
                <eb-label
                    [innerHTML]="'onboarding.premium_promotion.presentation' | transloco"
                    color="black"
                    size="lg"
                    textTransform="inherit"
                />
            }
        </div>
    </div>

    <div class="d-flex flex-column justify-content-center align-items-center gap-2">
        <eb-button-with-icon-alt
            (click)="startFreeTrial()"
            icon="ui/action/symbols/start"
        >
            {{ 'onboarding.premium_promotion.try_premium' | transloco }}
        </eb-button-with-icon-alt>
    </div>
</div>
